<template>
  <tr class="">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + unique + poem.id" v-model="isChecked"/>
        <label :for="'checkbox' + unique + poem.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2 whitespace-nowrap font-semibold">
      <router-link :to="{name: 'AdminPoemEdit', params: {id: poem.id}}">
        {{ poem.title }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link :to="{name: 'UserDetail', params: {id: poem.uploader.id}}" v-if="poem.uploader">
        {{ poem.uploader.username }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      {{ timeago(poem.created_at) }}
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link class="mr-3" :to="{name: 'SongDetail', params: {id: poem.id, slug: poem.slug, prefix: 'dan-ca'}}">
        Xem
      </router-link>
      <router-link :to="{name: 'AdminPoemEdit', params: {id: poem.id}}" class="mr-3">
        Sửa
      </router-link>
      <a href="javascript:void(0)" v-on:click="deletePoem(poem)">Xóa</a>
    </td>
  </tr>
</template>

<script>

import {
  timeago
} from "../../../core/services/utils.service";

export default {
  name: "AdminPoemItem",
  props: {
    poem: Object,
    unique: String
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
    deletePoem(poem) {
      this.$emit("deletePoem", poem);
    }
  },
  created() {
    this.isChecked = this.poem.is_checked;
  },
  watch: {
    "poem.is_checked": {
      handler(newVal) {
        this.isChecked = newVal;
      },
      deep: true
    },
    isChecked: {
      handler(newVal) {
        this.$emit("handleCheckedPoem", this.poem, newVal);
      }
    },
  }
}
</script>
